import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'

/* import specific icons */
import {
  faHouseFlag,
  faMapLocationDot,
  faCircleXmark,
  faFlagCheckered,
  faBars,
  faPlayCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faHouseFlag,
  faMapLocationDot,
  faCircleXmark,
  faFlagCheckered,
  faBars,
  faPlayCircle,
  faInfoCircle
)

Amplify.configure(awsconfig)
createApp(App)
  .use(store)
  .use(router)
  .use(VueCookieComply)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
