/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      nickname
      avatar
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
          members
        }
        nextToken
      }
      messages {
        items {
          content
          id
          createdAt
          updatedAt
          userMessagesId
          conversationMessagesId
          authorId
        }
        nextToken
      }
      locations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      closestLocations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      gameGuesses {
        items {
          round
          latitude
          longitude
          distance
          points
          id
          createdAt
          updatedAt
          userGameGuessesId
          gameGuesssesId
          owner
        }
        nextToken
      }
      games {
        items {
          id
          userID
          gameID
          createdAt
          updatedAt
          playerIDs
        }
        nextToken
      }
      visitedLocations {
        items {
          id
          guessLatitude
          guessLongitude
          distance
          city
          cityAlt
          country
          countryCode
          createdAt
          updatedAt
          userVisitedLocationsId
          locationVisitedId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      nickname
      avatar
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
          members
        }
        nextToken
      }
      messages {
        items {
          content
          id
          createdAt
          updatedAt
          userMessagesId
          conversationMessagesId
          authorId
        }
        nextToken
      }
      locations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      closestLocations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      gameGuesses {
        items {
          round
          latitude
          longitude
          distance
          points
          id
          createdAt
          updatedAt
          userGameGuessesId
          gameGuesssesId
          owner
        }
        nextToken
      }
      games {
        items {
          id
          userID
          gameID
          createdAt
          updatedAt
          playerIDs
        }
        nextToken
      }
      visitedLocations {
        items {
          id
          guessLatitude
          guessLongitude
          distance
          city
          cityAlt
          country
          countryCode
          createdAt
          updatedAt
          userVisitedLocationsId
          locationVisitedId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      nickname
      avatar
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
          members
        }
        nextToken
      }
      messages {
        items {
          content
          id
          createdAt
          updatedAt
          userMessagesId
          conversationMessagesId
          authorId
        }
        nextToken
      }
      locations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      closestLocations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      gameGuesses {
        items {
          round
          latitude
          longitude
          distance
          points
          id
          createdAt
          updatedAt
          userGameGuessesId
          gameGuesssesId
          owner
        }
        nextToken
      }
      games {
        items {
          id
          userID
          gameID
          createdAt
          updatedAt
          playerIDs
        }
        nextToken
      }
      visitedLocations {
        items {
          id
          guessLatitude
          guessLongitude
          distance
          city
          cityAlt
          country
          countryCode
          createdAt
          updatedAt
          userVisitedLocationsId
          locationVisitedId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      latitude
      longitude
      addedByID
      addedByUser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      closestUserID
      closestUser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      closestLatitude
      closestLongitude
      closestDistance
      visited {
        items {
          id
          guessLatitude
          guessLongitude
          distance
          city
          cityAlt
          country
          countryCode
          createdAt
          updatedAt
          userVisitedLocationsId
          locationVisitedId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      gameLocationsId
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      latitude
      longitude
      addedByID
      addedByUser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      closestUserID
      closestUser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      closestLatitude
      closestLongitude
      closestDistance
      visited {
        items {
          id
          guessLatitude
          guessLongitude
          distance
          city
          cityAlt
          country
          countryCode
          createdAt
          updatedAt
          userVisitedLocationsId
          locationVisitedId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      gameLocationsId
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      latitude
      longitude
      addedByID
      addedByUser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      closestUserID
      closestUser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      closestLatitude
      closestLongitude
      closestDistance
      visited {
        items {
          id
          guessLatitude
          guessLongitude
          distance
          city
          cityAlt
          country
          countryCode
          createdAt
          updatedAt
          userVisitedLocationsId
          locationVisitedId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      gameLocationsId
    }
  }
`;
export const createVisitedLocation = /* GraphQL */ `
  mutation CreateVisitedLocation(
    $input: CreateVisitedLocationInput!
    $condition: ModelVisitedLocationConditionInput
  ) {
    createVisitedLocation(input: $input, condition: $condition) {
      id
      location {
        latitude
        longitude
        addedByID
        addedByUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestUserID
        closestUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestLatitude
        closestLongitude
        closestDistance
        visited {
          nextToken
        }
        id
        createdAt
        updatedAt
        gameLocationsId
      }
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      guessLatitude
      guessLongitude
      distance
      city
      cityAlt
      country
      countryCode
      createdAt
      updatedAt
      userVisitedLocationsId
      locationVisitedId
      owner
    }
  }
`;
export const updateVisitedLocation = /* GraphQL */ `
  mutation UpdateVisitedLocation(
    $input: UpdateVisitedLocationInput!
    $condition: ModelVisitedLocationConditionInput
  ) {
    updateVisitedLocation(input: $input, condition: $condition) {
      id
      location {
        latitude
        longitude
        addedByID
        addedByUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestUserID
        closestUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestLatitude
        closestLongitude
        closestDistance
        visited {
          nextToken
        }
        id
        createdAt
        updatedAt
        gameLocationsId
      }
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      guessLatitude
      guessLongitude
      distance
      city
      cityAlt
      country
      countryCode
      createdAt
      updatedAt
      userVisitedLocationsId
      locationVisitedId
      owner
    }
  }
`;
export const deleteVisitedLocation = /* GraphQL */ `
  mutation DeleteVisitedLocation(
    $input: DeleteVisitedLocationInput!
    $condition: ModelVisitedLocationConditionInput
  ) {
    deleteVisitedLocation(input: $input, condition: $condition) {
      id
      location {
        latitude
        longitude
        addedByID
        addedByUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestUserID
        closestUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestLatitude
        closestLongitude
        closestDistance
        visited {
          nextToken
        }
        id
        createdAt
        updatedAt
        gameLocationsId
      }
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      guessLatitude
      guessLongitude
      distance
      city
      cityAlt
      country
      countryCode
      createdAt
      updatedAt
      userVisitedLocationsId
      locationVisitedId
      owner
    }
  }
`;
export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      playerIDs
      players {
        items {
          id
          userID
          gameID
          createdAt
          updatedAt
          playerIDs
        }
        nextToken
      }
      status
      rounds
      currentRound
      locations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      guessses {
        items {
          round
          latitude
          longitude
          distance
          points
          id
          createdAt
          updatedAt
          userGameGuessesId
          gameGuesssesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      playerIDs
      players {
        items {
          id
          userID
          gameID
          createdAt
          updatedAt
          playerIDs
        }
        nextToken
      }
      status
      rounds
      currentRound
      locations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      guessses {
        items {
          round
          latitude
          longitude
          distance
          points
          id
          createdAt
          updatedAt
          userGameGuessesId
          gameGuesssesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      playerIDs
      players {
        items {
          id
          userID
          gameID
          createdAt
          updatedAt
          playerIDs
        }
        nextToken
      }
      status
      rounds
      currentRound
      locations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      guessses {
        items {
          round
          latitude
          longitude
          distance
          points
          id
          createdAt
          updatedAt
          userGameGuessesId
          gameGuesssesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createGameGuess = /* GraphQL */ `
  mutation CreateGameGuess(
    $input: CreateGameGuessInput!
    $condition: ModelGameGuessConditionInput
  ) {
    createGameGuess(input: $input, condition: $condition) {
      round
      game {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      guesser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      latitude
      longitude
      distance
      points
      id
      createdAt
      updatedAt
      userGameGuessesId
      gameGuesssesId
      owner
    }
  }
`;
export const updateGameGuess = /* GraphQL */ `
  mutation UpdateGameGuess(
    $input: UpdateGameGuessInput!
    $condition: ModelGameGuessConditionInput
  ) {
    updateGameGuess(input: $input, condition: $condition) {
      round
      game {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      guesser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      latitude
      longitude
      distance
      points
      id
      createdAt
      updatedAt
      userGameGuessesId
      gameGuesssesId
      owner
    }
  }
`;
export const deleteGameGuess = /* GraphQL */ `
  mutation DeleteGameGuess(
    $input: DeleteGameGuessInput!
    $condition: ModelGameGuessConditionInput
  ) {
    deleteGameGuess(input: $input, condition: $condition) {
      round
      game {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      guesser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      latitude
      longitude
      distance
      points
      id
      createdAt
      updatedAt
      userGameGuessesId
      gameGuesssesId
      owner
    }
  }
`;
export const createConvo = /* GraphQL */ `
  mutation CreateConvo(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConvo(input: $input, condition: $condition) {
      messages {
        items {
          content
          id
          createdAt
          updatedAt
          userMessagesId
          conversationMessagesId
          authorId
        }
        nextToken
      }
      name
      members
      users {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
          members
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      author {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      content
      conversation {
        messages {
          nextToken
        }
        name
        members
        users {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
      userMessagesId
      conversationMessagesId
      authorId
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      author {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      content
      conversation {
        messages {
          nextToken
        }
        name
        members
        users {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
      userMessagesId
      conversationMessagesId
      authorId
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      author {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      content
      conversation {
        messages {
          nextToken
        }
        name
        members
        users {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
      userMessagesId
      conversationMessagesId
      authorId
    }
  }
`;
export const createUserConversations = /* GraphQL */ `
  mutation CreateUserConversations(
    $input: CreateUserConversationsInput!
    $condition: ModelUserConversationsConditionInput
  ) {
    createUserConversations(input: $input, condition: $condition) {
      id
      userID
      conversationID
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      conversation {
        messages {
          nextToken
        }
        name
        members
        users {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      members
    }
  }
`;
export const updateUserConversations = /* GraphQL */ `
  mutation UpdateUserConversations(
    $input: UpdateUserConversationsInput!
    $condition: ModelUserConversationsConditionInput
  ) {
    updateUserConversations(input: $input, condition: $condition) {
      id
      userID
      conversationID
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      conversation {
        messages {
          nextToken
        }
        name
        members
        users {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      members
    }
  }
`;
export const deleteUserConversations = /* GraphQL */ `
  mutation DeleteUserConversations(
    $input: DeleteUserConversationsInput!
    $condition: ModelUserConversationsConditionInput
  ) {
    deleteUserConversations(input: $input, condition: $condition) {
      id
      userID
      conversationID
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      conversation {
        messages {
          nextToken
        }
        name
        members
        users {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      members
    }
  }
`;
export const createUserGames = /* GraphQL */ `
  mutation CreateUserGames(
    $input: CreateUserGamesInput!
    $condition: ModelUserGamesConditionInput
  ) {
    createUserGames(input: $input, condition: $condition) {
      id
      userID
      gameID
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      game {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      playerIDs
    }
  }
`;
export const updateUserGames = /* GraphQL */ `
  mutation UpdateUserGames(
    $input: UpdateUserGamesInput!
    $condition: ModelUserGamesConditionInput
  ) {
    updateUserGames(input: $input, condition: $condition) {
      id
      userID
      gameID
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      game {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      playerIDs
    }
  }
`;
export const deleteUserGames = /* GraphQL */ `
  mutation DeleteUserGames(
    $input: DeleteUserGamesInput!
    $condition: ModelUserGamesConditionInput
  ) {
    deleteUserGames(input: $input, condition: $condition) {
      id
      userID
      gameID
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      game {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      playerIDs
    }
  }
`;
