<template>
  <div v-if="loading" id="loadingBackground" class="fullpage">
    <h2 v-show="loading && loggedIn" class="loading-text">
      gathering locations...
    </h2>
  </div>
  <div>
    <button
      class="side-button-play"
      draggable="false"
      aria-label="Play again"
      title="Play again"
      type="button"
      aria-pressed="false"
      @click="play"
    >
      <font-awesome-icon icon="fa-solid fa-play-circle" />
    </button>
  </div>

  <div id="map" v-show="locations" class="fullscreen-map"></div>
</template>

<script>
import LocationService from '@/services/LocationService'
import store from '@/store'
import { Loader } from '@googlemaps/js-api-loader'
import { toRaw } from 'vue'
import { Auth } from 'aws-amplify'
import GLOBE from 'vanta/dist/vanta.globe.min'
import router from '@/router'

export default {
  name: 'WhereHaveIBeen',
  data() {
    return {
      locations: null,
      map: null,
      goalMarker: null,
      google: null,
      loggedIn: false,
      loading: false,
      loadingScreen: null,
    }
  },
  created() {
    this.loading = true
    console.log('created started')
    const loader = new Loader({
      apiKey: process.env.VUE_APP_MAPS_API_KEY,
      version: 'weekly',
    })

    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user != null) {
          console.log('user from currentAuthenticatedUser: ', user)
          store.commit('setUser', user)
          this.loggedIn = true
          console.log('found user')
          loader
            .load()
            .then((google) => {
              console.log('ggogle loaded')
              this.getLocations().then(() => {
                this.setMap(google)
              })
            })
            .catch((e) => {
              // do something
              console.log(e)
            })
        }
      })
      .catch((e) => {
        console.log(e)
        this.showMenu = true
        this.showGuessMap = false
        this.showLoading()
      })
  },
  methods: {
    showLoading() {
      this.loading = true
      if (this.loadingScreen == null) {
        this.loadingScreen = GLOBE({
          el: '#loadingBackground',
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x6500c5,
        })
      }
    },
    async getLocations() {
      this.showLoading()
      console.log('no locations, requesting now')

      await LocationService.listVisitedLocations().then((locations) => {
        this.locations = locations.data.listVisitedLocations.items
        console.log('this.locations: ', this.locations)
      })

      this.loading = false
      return Promise.resolve(this.locations)
    },
    async setMap(google) {
      console.log('set map', this.locations)
      this.google = google

      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 3,
        minZoom: 3,
        center: { lat: 24, lng: 4 },
        fullscreenControl: false,
        addressControl: false,
        enableCloseButton: false,
        motionTrackingControl: false,
        streetViewControl: false,
        gestureHandling: 'greedy',
      })
      var geocoder = new this.google.maps.Geocoder()
      for (const location of this.locations) {
        await new Promise((r) => setTimeout(r, 500))

        var coords = {
          lat: location.location.latitude,
          lng: location.location.longitude,
        }
        console.log('location for marker: ', coords)
        var marker = new this.google.maps.Marker({
          position: coords,
          map: this.map,
        })
        await this.createInfoForMarker(location, marker, geocoder, coords)
      }

      this.showGuessMap = false
    },
    async createInfoForMarker(location, marker, geocoder, coords) {
      if (location.city == null) {
        console.log('no city, updating now')

        await LocationService.getLocationInformation(geocoder, coords).then(
          (info) => {
            if (info.city != null) {
              LocationService.upateVisitedLocation(
                location.id,
                info.city,
                info.cityAlt,
                info.country,
                info.countryCode
              )
            }
            var contentString = this.getContentString(
              info.city,
              info.cityAlt,
              info.country,
              info.distance
            )
            const infowindow = new this.google.maps.InfoWindow({
              content: contentString,
            })

            marker.addListener('click', () => {
              infowindow.open({
                anchor: marker,
                map: this.map,
                shouldFocus: false,
              })
            })
          }
        )
      } else {
        var contentString = this.getContentString(
          location.city,
          location.cityAlt,
          location.country,
          location.distance
        )
        const infowindow = new this.google.maps.InfoWindow({
          content: contentString,
        })

        marker.addListener('click', () => {
          infowindow.open({
            anchor: marker,
            map: this.map,
            shouldFocus: false,
          })
        })
      }
    },
    getContentString(city, cityAlt, country, distance) {
      return (
        '<div id="content">' +
        '<div id="siteNotice">' +
        '</div>' +
        '<h1 id="firstHeading" class="firstHeading">' +
        city +
        ', ' +
        cityAlt +
        ' ' +
        country +
        '</h1>' +
        '<div id="bodyContent">' +
        '<h3>You were ' +
        distance +
        ' away!</h3>' +
        '</div>' +
        '</div>'
      )
    },
    dropMarker(event) {
      console.log(this.guessMarker)
      if (this.guessMarker != null) {
        console.log(this.guessMarker.position)
        toRaw(this.guessMarker).setMap(null)
      }
      this.guessMarker = new this.google.maps.Marker({
        position: event.latLng,
        map: this.guessMap,
        icon: 'http://maps.google.com/mapfiles/kml/paddle/purple-circle-lv.png',
      })
    },
    play() {
      router.push('/')
    },
  },
}
</script>

<style scoped>
.welcome-container {
  margin: 20px;
  font-size: 1.1em;
  color: #c002eb;
}

.fullscreen-map {
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}

.side-button-play {
  position: absolute;
  z-index: 1000;
  top: 50px;
  left: 0px;
  width: 40px;
  height: 40px;
  margin: 10px;
  font-size: 1.2em;
  background: none rgb(34, 34, 34);
  color: lightgray;
  border: 0px;
  padding: 0;
  cursor: pointer;
}
.side-button-play:hover {
  color: white;
}

.vanta-canvas {
  height: 100vh !important;
}
.fullpage {
  position: absolute;
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  z-index: 900;
}
.loading-text {
  position: absolute;
  top: 40%;
  left: 35%;
  color: #d0b2ed;
  height: 100vh;
  vertical-align: middle;
}
</style>
