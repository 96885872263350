<template>
  <div v-if="loading" id="loadingBackground" class="fullpage">
    <h2 v-show="loading && loggedIn" class="loading-text">
      gathering locations...
    </h2>
  </div>
  <div>
    <button
      class="side-button-info"
      draggable="false"
      aria-label="Show info"
      title="info"
      type="button"
      aria-pressed="false"
      @click="showInfo = !showInfo"
    >
      <font-awesome-icon icon="fa-solid fa-info-circle" />
    </button>

    <button
      class="side-button-home"
      draggable="false"
      aria-label="Go to start"
      title="Back to start"
      type="button"
      aria-pressed="false"
      @click="backToStart"
    >
      <font-awesome-icon icon="fa-solid fa-house-flag" />
    </button>
    <button
      class="side-button-guess"
      draggable="false"
      aria-label="Open guess"
      title="Guess"
      type="button"
      aria-pressed="false"
      @click="guess"
    >
      <font-awesome-icon icon="fa-solid fa-map-location-dot" />
    </button>
    <button
      class="side-button-play"
      draggable="false"
      aria-label="Play again"
      title="Play again"
      type="button"
      aria-pressed="false"
      @click="playAgain"
      v-show="gameOver"
    >
      <font-awesome-icon icon="fa-solid fa-play-circle" />
    </button>
  </div>
  <Transition name="slide">
    <div v-show="showGuessControls">
      <button
        class="side-button-close-guess"
        draggable="false"
        aria-label="Close guess"
        title="Close guess"
        type="button"
        aria-pressed="false"
        @click="guess"
      >
        <font-awesome-icon icon="fa-solid fa-circle-xmark" />
      </button>
      <button
        class="side-button-submit-guess"
        draggable="false"
        aria-label="submit guess"
        title="Submit guess"
        type="button"
        aria-pressed="false"
        :disabled="gameOver"
        @click="submitGuess"
      >
        <font-awesome-icon icon="fa-solid fa-flag-checkered" />
      </button>
    </div>
  </Transition>

  <button
    class="side-button-menu"
    draggable="false"
    aria-label="Open menu"
    title="Open menu"
    type="button"
    aria-pressed="false"
    @click="showMenu = !showMenu"
  >
    <font-awesome-icon icon="fa-solid fa-bars" />
  </button>

  <Transition name="fade" v-show="showMenu">
    <div class="navigation-menu">
      <div>
        <authenticator>
          <template v-slot="{ user, signOut }">
            <div class="navigation-item welcome">
              hello, {{ user.username }}!
            </div>
            <div class="navigation-item" @click="signOut">
              <a class="navigation-link">sign out</a>
            </div>
          </template>
        </authenticator>
      </div>
      <hr class="menu-line" />
      <div class="navigation-item" @click="goToLocations" v-if="loggedIn">
        <a class="navigation-link">Add locations</a>
      </div>
      <div class="navigation-item" @click="goToWhere" v-if="loggedIn">
        <a class="navigation-link">Where have I been?</a>
      </div>
    </div>
  </Transition>

  <div id="map" v-show="location" class="streetView"></div>
  <Transition name="slide">
    <div id="guessMap" class="guess-map" v-show="showGuessMap"></div>
  </Transition>
  <InfoView v-if="showInfo" @closeInfo="showInfo = false"></InfoView>
</template>

<script setup>
import { Authenticator } from '@aws-amplify/ui-vue'
</script>

<script>
import LocationService from '@/services/LocationService'
import store from '@/store'
import { Loader } from '@googlemaps/js-api-loader'
import { toRaw } from 'vue'
import { Auth } from 'aws-amplify'
import GLOBE from 'vanta/dist/vanta.globe.min'
import router from '@/router'
import InfoView from '@/views/InfoView.vue'

export default {
  name: 'StreetView',
  data() {
    return {
      location: null,
      map: null,
      guessMap: null,
      showGuessMap: true,
      showGuessControls: false,
      guessMarker: null,
      goalMarker: null,
      lineToFinish: null,
      google: null,
      showMenu: false,
      gameOver: false,
      loggedIn: false,
      loading: false,
      loadingScreen: null,
      showInfo: false,
    }
  },
  created() {
    this.loading = true
    const loader = new Loader({
      apiKey: process.env.VUE_APP_MAPS_API_KEY,
      version: 'weekly',
    })

    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user != null) {
          store.commit('setUser', user)
          this.loggedIn = true
          loader
            .load()
            .then((google) => {
              this.getLocation().then(() => {
                this.setMap(google)
              })
            })
            .catch((e) => {
              // do something
              console.log(e)
            })
        }
      })
      .catch((e) => {
        console.log(e)
        this.showGuessMap = false
        this.showInfo = true
        this.showLoading()
        loader
          .load()
          .then((google) => {
            this.getLocalLocation().then(() => {
              this.setMap(google)
            })
          })
          .catch((e) => {
            // do something
            console.log(e)
          })
      })
  },
  methods: {
    showLoading() {
      this.loading = true
      if (this.loadingScreen == null) {
        this.loadingScreen = GLOBE({
          el: '#loadingBackground',
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x6500c5,
        })
      }
    },
    async getLocation() {
      if (store.state.locations.length == 0) {
        this.showLoading()

        await LocationService.getRandomLocations()
          .then(() => {
            this.location = store.getters.useLocation
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.location = store.getters.useLocation
      }

      this.loading = false
      return Promise.resolve(this.location)
    },
    async getLocalLocation() {
      await LocationService.getRandomLocalLocations()
        .then((location) => {
          this.location = location
        })
        .catch(() => {
          this.loading = false
        })

      this.loading = false
      return Promise.resolve(this.location)
    },
    setMap(google) {
      this.google = google
      this.map = new google.maps.StreetViewPanorama(
        document.getElementById('map'),
        {
          position: {
            lat: this.location.latitude,
            lng: this.location.longitude,
          },
          addressControl: false,
          enableCloseButton: false,
          fullscreenControl: false,
          motionTrackingControl: false,
          linksControl: false,
          pov: {
            heading: 0,
            pitch: 0,
          },
        }
      )

      this.guessMap = new google.maps.Map(document.getElementById('guessMap'), {
        zoom: 3,
        minZoom: 3,
        center: { lat: 0, lng: 0 },
        fullscreenControl: false,
        addressControl: false,
        enableCloseButton: false,
        motionTrackingControl: false,
        streetViewControl: false,
        gestureHandling: 'greedy',
      })

      google.maps.event.addListener(this.guessMap, 'click', this.dropMarker)

      this.showGuessMap = false
    },
    dropMarker(event) {
      if (this.gameOver) {
        return
      }

      if (this.guessMarker != null) {
        toRaw(this.guessMarker).setMap(null)
      }
      this.guessMarker = new this.google.maps.Marker({
        position: event.latLng,
        map: this.guessMap,
        icon: 'http://maps.google.com/mapfiles/kml/paddle/purple-circle-lv.png',
      })
    },
    backToStart() {
      this.map.setPosition({
        lat: this.location.latitude,
        lng: this.location.longitude,
      })
    },
    guess() {
      this.showGuessMap = !this.showGuessMap
      this.showGuessControls = !this.showGuessControls
    },
    submitGuess() {
      var label = 'meters'
      var distance = this.google.maps.geometry.spherical.computeDistanceBetween(
        { lat: this.location.latitude, lng: this.location.longitude },
        this.guessMarker.position
      )
      var successString = 'Not Horrible!'

      if (distance > 1000000) {
        successString = 'Study Geography!'
      } else if (distance > 100000) {
        successString = 'Not Great!'
      } else if (distance > 10000) {
        successString = 'Not Horrible!'
      } else if (distance > 50) {
        successString = 'Pretty Good!'
      } else if (distance > 10) {
        successString = 'Amazing!'
      } else {
        successString = 'You might as well move here!'
      }

      if (distance > 1000) {
        label = 'kilometers'
        distance = distance / 1000
      }

      this.goalMarker = new this.google.maps.Marker({
        position: { lat: this.location.latitude, lng: this.location.longitude },
        map: this.guessMap,
        icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-circle-lv.png',
      })
      this.gameOver = true
      const pathToFinish = [
        { lat: this.location.latitude, lng: this.location.longitude },
        this.guessMarker.position,
      ]
      this.lineToFinish = new this.google.maps.Polyline({
        path: pathToFinish,
        geodesic: true,
        strokeColor: '#c002eb',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      })

      this.lineToFinish.setMap(this.guessMap)
      var distanceString = distance.toFixed(2) + ' ' + label
      var contentString =
        '<div id="content">' +
        '<div id="siteNotice">' +
        '</div>' +
        '<h1 id="firstHeading" class="firstHeading">' +
        successString +
        '</h1>' +
        '<div id="bodyContent">' +
        '<h3>You were ' +
        distanceString +
        ' away!</h3>' +
        '</div>' +
        '</div>'

      const infowindow = new this.google.maps.InfoWindow({
        content: contentString,
      })

      this.goalMarker.addListener('click', () => {
        infowindow.open({
          anchor: this.goalMarker,
          map: this.guessMap,
          shouldFocus: false,
        })
      })

      infowindow.open({
        anchor: this.goalMarker,
        map: this.guessMap,
        shouldFocus: false,
      })

      this.map.setPosition({
        lat: this.location.latitude,
        lng: this.location.longitude,
      })

      // "216079b0-f699-4a6f-9e7e-743b4d09bf85"
      if (this.loggedIn) {
        var geocoder = new this.google.maps.Geocoder()
        LocationService.useLocation(
          this.location,
          geocoder,
          {
            latitude: this.guessMarker.position.lat(),
            longitude: this.guessMarker.position.lng(),
          },
          distanceString
        ).then((visitedLocation) => {
          console.log('visited location: ', visitedLocation)
        })
      }
    },
    playAgain() {
      if (!this.loggedIn) {
        this.getLocalLocation().then((location) => {
          this.map.setPosition({
            lat: location.latitude,
            lng: location.longitude,
          })
          this.resetGame()
        })
      }
      this.getLocation().then((location) => {
        this.map.setPosition({
          lat: location.latitude,
          lng: location.longitude,
        })
        this.resetGame()
      })
    },
    resetGame() {
      toRaw(this.goalMarker).setMap(null)
      toRaw(this.lineToFinish).setMap(null)
      this.guessMap.setCenter({ lat: 0, lng: 0 })
      this.guessMap.setZoom(3)
      this.map.setZoom(0)
      this.map.setPov({ heading: 0, pitch: 0 })
      this.showGuessMap = false
      this.gameOver = false
      if (this.guessMarker != null) {
        toRaw(this.guessMarker).setMap(null)
      }
    },
    goToWhere() {
      router.push('/wherehaveibeen')
    },
    goToLocations() {
      router.push('/locations')
    },
  },
}
</script>

<style scoped>
.not-logged-in-menu-container {
  text-align: left;
  width: 300px;
  color: white;
  padding: 10px;
}
.welcome {
  font-size: 1.5em;
  color: #c002eb;
  text-align: left;
}
.navigation-item {
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}
.navigation-link {
  margin-left: 0px;
  color: #ccc;
  text-decoration: none;
}
.navigation-item:hover {
  background-color: #65007c;
}
.menu-line {
  border-color: #666;
}
.navigation-menu {
  background: none rgb(34, 34, 34);
  left: 40px;
  top: 5px;
  margin: 10px;
  position: fixed;
  z-index: 1000;
  text-align: left;
}
.streetView {
  position: absolute;
  overflow: hidden;
  left: 0;
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  height: -webkit-fill-available;
  /* padding-bottom: calc(1em + env(safe-area-inset-bottom)); */
}

.guess-map {
  position: absolute;
  right: 0;
  width: 50vw;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  height: -webkit-fill-available;
  z-index: 2000;
  box-shadow: inset;
}

.side-menu {
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 0px;
  margin: 10px;
}
.side-button-menu {
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 0px;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  background: none rgb(34, 34, 34);
  border: 0px;
  padding: 0;
  color: lightgray;
  cursor: pointer;
}
.side-button-menu:hover {
  color: white;
}
.side-button-info {
  position: absolute;
  z-index: 1000;
  top: 60px;
  left: 0px;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  background: none rgb(34, 34, 34);
  border: 0px;
  padding: 0;
  color: lightgray;
  cursor: pointer;
}
.side-button-info:hover {
  color: white;
}

.side-button-home {
  position: absolute;
  z-index: 1000;
  top: 100px;
  left: 0px;
  margin: 10px;
  width: 40px;
  height: 40px;
  background: none rgb(34, 34, 34);
  border: 0px;
  padding: 0;
  color: lightgray;
  cursor: pointer;
}
.side-button-home:hover {
  color: white;
}

.side-button-guess {
  position: absolute;
  z-index: 1000;
  top: 150px;
  left: 0px;
  width: 40px;
  height: 40px;
  margin: 10px;
  font-size: 1.2em;
  background: none rgb(34, 34, 34);
  color: rgb(0, 200, 119);
  border: 0px;
  padding: 0;
  cursor: pointer;
}
.side-button-guess:hover {
  color: white;
}

.side-button-play {
  position: absolute;
  z-index: 1000;
  top: 150px;
  left: 0px;
  width: 40px;
  height: 40px;
  margin: 10px;
  font-size: 1.2em;
  background: none rgb(34, 34, 34);
  color: lightgray;
  border: 0px;
  padding: 0;
  cursor: pointer;
}
.side-button-play:hover {
  color: white;
}

.side-button-submit-guess {
  position: absolute;
  z-index: 4000;
  bottom: 10px;
  right: 55px;
  width: 60px;
  height: 60px;
  margin: 10px;
  font-size: 1.6em;
  border-radius: 30px;
  background: none rgb(34, 34, 34);
  color: #0beaab;
  border: 0px;
  padding: 0;
  cursor: pointer;
}
.side-button-submit-guess:hover {
  color: #77f9d4;
}

.side-button-close-guess {
  position: absolute;
  z-index: 4000;
  top: 10px;
  right: 0px;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  font-size: 1.5em;
  background: transparent;
  border: 0px;
  padding: 0;
  color: rgb(50, 50, 50);
  cursor: pointer;
}
.side-button-close-guess:hover {
  color: rgb(100, 100, 100);
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter-from {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.vanta-canvas {
  height: 100vh !important;
}
.fullpage {
  position: absolute;
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  z-index: 900;
}
.loading-text {
  position: absolute;
  top: 40%;
  left: 35%;
  color: #d0b2ed;
  height: 100vh;
  vertical-align: middle;
}
</style>
