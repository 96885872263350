import Auth from '@aws-amplify/auth'
import store from '../store/index.js'
import { Hub } from '@aws-amplify/core'
import router from '@/router'

Hub.listen('auth', async (data) => {
  if (data.payload.event === 'signOut') {
    store.commit('setUser', null)
  } else if (data.payload.event === 'signIn') {
    console.log('hub')
    router.go()
  }
})

export default {
  async getUser() {
    return Auth.currentAuthenticatedUser()
      .then((data) => {
        if (data && data.signInUserSession) {
          store.commit('setUser', data)
          console.log('logged in', data)
        }
      })
      .catch(() => {
        store.commit('setUser', null)
      })
  },
}
