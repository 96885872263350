<template>
  <div id="guessMap" class="guess-map"></div>
  <div id="map" v-if="location" class="streetView"></div>

  <button
    class="side-button-menu"
    draggable="false"
    aria-label="Open menu"
    title="Open menu"
    type="button"
    aria-pressed="false"
    @click="showMenu = !showMenu"
  >
    <font-awesome-icon icon="fa-solid fa-bars" />
  </button>
  <Transition name="fade" v-show="showMenu">
    <div class="login-container">
      <authenticator>
        <template v-slot="{ user, signOut }">
          <div class="welcome-container">
            <span class="welcome">hello {{ user.username }}!</span>
            <a class="signout-link" @click="signOut">sign out</a>
          </div>
        </template>
      </authenticator>
    </div>
  </Transition>

  <!-- <button @click="generateRandomPoint">Generate Location</button> -->
  <button @click="addLocation" :disabled="!canAdd">Add Location</button>
  <!-- <button @click="listLocations">List Location</button> -->
  <!-- <button @click="deleteLocation">Delete Location</button> -->
  <div class="info-view">
    <span v-if="locationInformation"
      >{{ this.locationInformation.city }},
      {{ this.locationInformation.cityAlt }}
      {{ this.locationInformation.countryCode }}</span
    >
    <li
      v-for="item in processedLocations"
      :key="item.id"
      style="text-align: left"
    >
      {{ item.id }}: {{ item.location.lat }}, {{ item.location.lng }} -
      {{ item.status }}
    </li>
  </div>
</template>
<script setup>
import { Authenticator } from '@aws-amplify/ui-vue'
</script>

<script>
import EventService from '@/services/EventService.js'
import LocationService from '@/services/LocationService'
import { Loader } from '@googlemaps/js-api-loader'
import { toRaw } from 'vue'

export default {
  name: 'LocationsView',
  data() {
    return {
      location: { lat: 32.8311514, lng: -117.2826938 },
      map: null,
      guessMap: null,
      showGuessMap: true,
      guessMarker: null,
      google: null,
      showMenu: false,
      locationInformation: null,
      canAdd: false,
      processedLocations: [],
      processId: 0,
      goodLocations: [],
    }
  },
  created() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_MAPS_API_KEY,
      version: 'weekly',
    })
    loader
      .load()
      .then((google) => {
        this.setMap(google)
      })
      .catch((e) => {
        // do something
        console.log(e)
      })
    EventService.getDailyWhereAmI()
      .then((response) => {
        this.location = response.location
        this.setMap(this.google)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    setMap(google) {
      this.google = google
      this.map = new google.maps.StreetViewPanorama(
        document.getElementById('map'),
        {
          position: this.location,
          addressControl: false,
          enableCloseButton: false,
          fullscreenControl: false,
          motionTrackingControl: false,
          linksControl: false,
          pov: {
            heading: 34,
            pitch: 10,
          },
        }
      )

      this.guessMap = new google.maps.Map(document.getElementById('guessMap'), {
        zoom: 3,
        minZoom: 3,
        center: { lat: 0, lng: 0 },
        fullscreenControl: false,
        addressControl: false,
        enableCloseButton: false,
        motionTrackingControl: false,
        streetViewControl: false,
        gestureHandling: 'greedy',
      })

      google.maps.event.addListener(this.guessMap, 'click', this.dropMarker)

      this.showGuessMap = false
    },
    dropMarker(event) {
      console.log(this.guessMarker)
      if (this.guessMarker != null) {
        console.log(this.guessMarker.position)
        toRaw(this.guessMarker).setMap(null)
      }
      this.guessMarker = new this.google.maps.Marker({
        position: event.latLng,
        map: this.guessMap,
      })
      this.map.setPosition(this.guessMarker.position)
      this.canAdd = true
    },
    backToStart() {
      this.map.setPosition(this.location)
    },
    guess() {
      this.showGuessMap = !this.showGuessMap
    },
    addLocation() {
      var mapLocation = this.map.getPosition().toJSON()
      LocationService.createLocation(mapLocation)
        .then((response) => {
          var location = toRaw(response)
          console.log(location)
          this.location = {
            lat: location.data.createLocation.latitude,
            lng: location.data.createLocation.latitude,
          }
          this.map.setPosition(this.location)
          this.canAdd = false
          alert('location added!')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteLocation() {
      LocationService.deleteLocation('da4215ec-39c1-40a3-9f41-f821796280ee')
        .then((response) => {
          var location = toRaw(response)
          console.log(location)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listLocations() {
      var geocoder = new this.google.maps.Geocoder()
      var mapLocation = this.map.getPosition().toJSON()
      var locationToAdd = toRaw(mapLocation)
      var coords = {
        lat: locationToAdd.lat,
        lng: locationToAdd.lng,
      }
      LocationService.getLocationInformation(geocoder, coords).then((info) => {
        console.log('location info: ', info)
      })
      // LocationService.listLocations()
      //   .then((response) => {
      //     var locations = toRaw(response)
      //     console.log(locations)
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
    },
    async generateRandomPoint() {
      // console.log('generate random point')
      // this.getLocationInformation(this.location).then((data) => {
      //   console.log('location information: ', data)
      //   this.locationInformation = data
      // })
      var batchLocations = LocationService.getLocalLocations()
      console.log('batchLocations: ', batchLocations)
      var sv = new this.google.maps.StreetViewService()
      this.processId = 0
      for (var x = 0; x < batchLocations.length; x++) {
        sv.getPanoramaByLocation(batchLocations[x], 500, this.processSVData)
        this.processId++
        await new Promise((r) => setTimeout(r, 200))
      }

      console.log('goodLocations: ', this.goodLocations)
      LocationService.createLocations(this.goodLocations)
        .then((response) => {
          var locations = toRaw(response)
          console.log('locations added: ', locations)
          alert(locations.length + ' locations added!')
        })
        .catch((error) => {
          console.log(error)
        })

      // var lat = Math.random() * 180 - 90
      // var lon = Math.random() * 360 - 180
      // this.location = { lat: lat, lng: lon }
      // var location = toRaw(this.location)
      // console.log(location)
    },
    processSVData(data, status) {
      if (status == this.google.maps.StreetViewStatus.OK) {
        console.log(data)
        this.processedLocations.push({
          id: this.processId,
          location: data,
          status: 'Good! :)',
        })
        console.log('EE ' + data.location.latLng.toUrlValue(6))
        console.log('data ', data)

        var lat = data.location.latLng.lat
        var lng = data.location.latLng.lng
        var locationData = {
          latitude: lat(),
          longitude: lng(),
        }
        console.log('locationData: ', locationData)

        this.goodLocations.push(locationData)
        // this.map.setPosition(data.location.latLng)
      } else {
        this.processedLocations.push({
          id: this.processId,
          location: {},
          status: 'BAD! :()',
        })
      }
    },
    async getLocationInformation(latLng) {
      var country = null
      var countryCode = null
      var city = null
      var cityAlt = null
      var geocoder = new this.google.maps.Geocoder()

      await geocoder
        .geocode({ location: latLng })
        .then((response) => {
          if (response.results[0]) {
            let results = response.results[0].address_components
            console.log(results)
            for (var r = 0; r < results.length; r += 1) {
              var result = results[r]

              if (!city && result.types[0] === 'locality') {
                city = result.long_name
              } else if (
                !cityAlt &&
                result.types[0] === 'administrative_area_level_1'
              ) {
                cityAlt = result.short_name
              } else if (!country && result.types[0] === 'country') {
                country = result.long_name
                countryCode = result.short_name
              }

              if (city && country) {
                break
              }
            }
          } else {
            console.log('no results were found')
          }
        })
        .catch((e) => window.alert('Geocoder failed due to: ' + e))

      var data = {
        city: city,
        cityAlt: cityAlt,
        country: country,
        countryCode: countryCode,
      }
      return Promise.resolve(data)
    },
  },
}
</script>

<style scoped>
.info-view {
  color: white;
}
.welcome-container {
  margin: 20px;
  font-size: 1.1em;
  color: #c002eb;
}
.signout-link {
  margin-left: 5px;
  color: #c002eb;
  cursor: pointer;
}
.login-container {
  background: none rgb(34, 34, 34);
  left: 0;
  top: 45px;
  margin: 10px;
  position: fixed;
  z-index: 1000;
}

.streetView {
  overflow: hidden;
  width: 50vw;
  height: calc(90vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}

.guess-map {
  position: absolute;
  right: 0;
  width: 50vw;
  height: calc(90vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  z-index: 2000;
  box-shadow: inset;
}

.side-menu {
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 0px;
  margin: 10px;
}
.side-button-menu {
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 0px;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  background: none rgb(34, 34, 34);
  border: 0px;
  padding: 0;
  color: lightgray;
  cursor: pointer;
}
.side-button-menu:hover {
  color: white;
}
</style>
