<template>
  <div class="info-container">
    <h1 class="title">Where Am I?</h1>
    <h4 class="subtitle">A location based game</h4>
    <div class="info-content">
      <h4>Quick Overview</h4>

      The goal is simple. Figure out where you are and guess closest to the
      start location. When you're ready to guess, simply click the
      <font-awesome-icon icon="fa-solid fa-map-location-dot" /> icon on the left
      and drop a pin on the guess map that appears. Click
      <font-awesome-icon icon="fa-solid fa-flag-checkered" /> to submit your
      guess.
    </div>
    <div class="info-content">
      <h4>Keep Traveling</h4>

      When you finish a game you can keep going with new locations. Click
      <font-awesome-icon icon="fa-solid fa-play-circle" /> to start at a new
      random location. guess.
    </div>
    <div class="info-content">
      <h4>Create an account</h4>
      Creating an account provides added benefits such as tracking the the
      places you visit. In the future, it will also allow you to challenge
      friends, climb leaderboards, and many other amazing things!
    </div>

    <button class="close-button" @click="$emit('closeInfo')">close</button>
  </div>
</template>

export default { name: 'InfoView', }
<style scoped>
.info-container {
  background: none rgba(34, 34, 35, 0.9);
  position: fixed;
  top: 30%;
  left: 30%;
  max-height: calc(
    70vh - env(safe-area-inset-bottom) - env(safe-area-inset-top)
  );
  transform: translate(-20%, -20%);
  z-index: 9999;
  color: white;
  overflow: auto;
}
.close-button {
  background-color: transparent;
  color: white;
  border-width: 1px;
  border-color: lightgray;
  margin: 10px;
}
.info-content {
  text-align: left;
  margin: 10px;
}
.info-content h4 {
  margin-bottom: 5px;
}
.title {
  margin-bottom: 0;
}
.subtitle {
  margin-top: 0;
}
</style>
