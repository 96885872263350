/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRandomLocation = /* GraphQL */ `
  query GetRandomLocation($count: Int) {
    getRandomLocation(count: $count) {
      id
      latitude
      longitude
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      nickname
      avatar
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
          members
        }
        nextToken
      }
      messages {
        items {
          content
          id
          createdAt
          updatedAt
          userMessagesId
          conversationMessagesId
          authorId
        }
        nextToken
      }
      locations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      closestLocations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      gameGuesses {
        items {
          round
          latitude
          longitude
          distance
          points
          id
          createdAt
          updatedAt
          userGameGuessesId
          gameGuesssesId
          owner
        }
        nextToken
      }
      games {
        items {
          id
          userID
          gameID
          createdAt
          updatedAt
          playerIDs
        }
        nextToken
      }
      visitedLocations {
        items {
          id
          guessLatitude
          guessLongitude
          distance
          city
          cityAlt
          country
          countryCode
          createdAt
          updatedAt
          userVisitedLocationsId
          locationVisitedId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      latitude
      longitude
      addedByID
      addedByUser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      closestUserID
      closestUser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      closestLatitude
      closestLongitude
      closestDistance
      visited {
        items {
          id
          guessLatitude
          guessLongitude
          distance
          city
          cityAlt
          country
          countryCode
          createdAt
          updatedAt
          userVisitedLocationsId
          locationVisitedId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      gameLocationsId
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        latitude
        longitude
        addedByID
        addedByUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestUserID
        closestUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestLatitude
        closestLongitude
        closestDistance
        visited {
          nextToken
        }
        id
        createdAt
        updatedAt
        gameLocationsId
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const getVisitedLocation = /* GraphQL */ `
  query GetVisitedLocation($id: ID!) {
    getVisitedLocation(id: $id) {
      id
      location {
        latitude
        longitude
        addedByID
        addedByUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestUserID
        closestUser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        closestLatitude
        closestLongitude
        closestDistance
        visited {
          nextToken
        }
        id
        createdAt
        updatedAt
        gameLocationsId
      }
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      guessLatitude
      guessLongitude
      distance
      city
      cityAlt
      country
      countryCode
      createdAt
      updatedAt
      userVisitedLocationsId
      locationVisitedId
      owner
    }
  }
`;
export const listVisitedLocations = /* GraphQL */ `
  query ListVisitedLocations(
    $filter: ModelVisitedLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisitedLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        user {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        guessLatitude
        guessLongitude
        distance
        city
        cityAlt
        country
        countryCode
        createdAt
        updatedAt
        userVisitedLocationsId
        locationVisitedId
        owner
      }
      nextToken
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      playerIDs
      players {
        items {
          id
          userID
          gameID
          createdAt
          updatedAt
          playerIDs
        }
        nextToken
      }
      status
      rounds
      currentRound
      locations {
        items {
          latitude
          longitude
          addedByID
          closestUserID
          closestLatitude
          closestLongitude
          closestDistance
          id
          createdAt
          updatedAt
          gameLocationsId
        }
        nextToken
        scannedCount
        count
      }
      guessses {
        items {
          round
          latitude
          longitude
          distance
          points
          id
          createdAt
          updatedAt
          userGameGuessesId
          gameGuesssesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGameGuess = /* GraphQL */ `
  query GetGameGuess($id: ID!) {
    getGameGuess(id: $id) {
      round
      game {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      guesser {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      latitude
      longitude
      distance
      points
      id
      createdAt
      updatedAt
      userGameGuessesId
      gameGuesssesId
      owner
    }
  }
`;
export const listGameGuesses = /* GraphQL */ `
  query ListGameGuesses(
    $filter: ModelGameGuessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameGuesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        round
        game {
          playerIDs
          status
          rounds
          currentRound
          id
          createdAt
          updatedAt
        }
        guesser {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        latitude
        longitude
        distance
        points
        id
        createdAt
        updatedAt
        userGameGuessesId
        gameGuesssesId
        owner
      }
      nextToken
    }
  }
`;
export const getConvo = /* GraphQL */ `
  query GetConvo($id: ID!) {
    getConvo(id: $id) {
      messages {
        items {
          content
          id
          createdAt
          updatedAt
          userMessagesId
          conversationMessagesId
          authorId
        }
        nextToken
      }
      name
      members
      users {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
          members
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const getUserConversations = /* GraphQL */ `
  query GetUserConversations($id: ID!) {
    getUserConversations(id: $id) {
      id
      userID
      conversationID
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      conversation {
        messages {
          nextToken
        }
        name
        members
        users {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      members
    }
  }
`;
export const listUserConversations = /* GraphQL */ `
  query ListUserConversations(
    $filter: ModelUserConversationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        conversationID
        user {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        conversation {
          name
          members
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        members
      }
      nextToken
    }
  }
`;
export const getUserGames = /* GraphQL */ `
  query GetUserGames($id: ID!) {
    getUserGames(id: $id) {
      id
      userID
      gameID
      user {
        id
        email
        nickname
        avatar
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        locations {
          nextToken
          scannedCount
          count
        }
        closestLocations {
          nextToken
          scannedCount
          count
        }
        gameGuesses {
          nextToken
        }
        games {
          nextToken
        }
        visitedLocations {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      game {
        playerIDs
        players {
          nextToken
        }
        status
        rounds
        currentRound
        locations {
          nextToken
          scannedCount
          count
        }
        guessses {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      playerIDs
    }
  }
`;
export const listUserGames = /* GraphQL */ `
  query ListUserGames(
    $filter: ModelUserGamesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        gameID
        user {
          id
          email
          nickname
          avatar
          createdAt
          updatedAt
          owner
        }
        game {
          playerIDs
          status
          rounds
          currentRound
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        playerIDs
      }
      nextToken
    }
  }
`;
