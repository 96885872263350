import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    userData: null,
    locations: [],
    currentLocation: 0,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setLocations(state, locations) {
      state.locations = JSON.parse(JSON.stringify(locations))
      console.log('store locations: ', state.locations)
      state.currentLocation = 0
    },
    useLocation(state) {
      state.locations.pop()
      state.currentLocationn++
    },
    setUserData(state, data) {
      state.userData = data
    },
  },
  getters: {
    isSignIn: (state) => {
      return state.user !== null
    },
    useLocation: (state) => {
      state.currentLocationn++
      var location = state.locations.pop()
      console.log('useLocation: ', location)
      return location
    },
    currentUsername: (state) => {
      return state.user.username
    },
  },
  actions: {},
  modules: {},
})
