import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '../views/AboutView.vue'
import StreetView from '../views/StreetView.vue'
import UserService from '../services/UserService'
import LocationsView from '../views/LocationsView.vue'
import WhereHaveIbeenView from '../views/WhereHaveIBeenView.vue'

UserService.getUser().then((user) => {
  if (user) {
    console.log(user)
  }
})

const routes = [
  {
    path: '/',
    name: 'Home',
    component: StreetView,
  },
  {
    path: '/locations',
    name: 'Locations',
    component: LocationsView,
  },
  {
    path: '/wherehaveIbeen',
    name: 'WhereHaveIBeen',
    component: WhereHaveIbeenView,
  },
  {
    path: '/daily',
    name: 'DailyWhereAmI',
    component: StreetView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
