import { API } from 'aws-amplify'
import {
  createLocation,
  deleteLocation,
  createVisitedLocation,
  updateVisitedLocation,
} from '../graphql/mutations'
import {
  getLocation,
  listLocations,
  listVisitedLocations,
  getRandomLocation,
} from '../graphql/queries'
import { toRaw } from 'vue'
import store from '@/store'

export default {
  async getDailyLocation() {
    const location = await API.graphql({
      query: getLocation,
      variables: {
        id: 1,
      },
    })
    return Promise.resolve(location)
  },
  async createLocation(latLng) {
    var locationToAdd = toRaw(latLng)
    console.log('create location: ', locationToAdd)
    var input = {
      latitude: locationToAdd.lat,
      longitude: locationToAdd.lng,
    }

    const location = await API.graphql({
      query: createLocation,
      variables: {
        input: input,
      },
    })

    return Promise.resolve(location)
  },
  async createLocations(latLngs) {
    var locations = []
    for (var x = 0; x < latLngs.length; x++) {
      var input = {
        latitude: latLngs[x].latitude,
        longitude: latLngs[x].longitude,
      }
      const location = await API.graphql({
        query: createLocation,
        variables: {
          input: input,
        },
      })

      locations.push(location)
    }

    return Promise.resolve(locations)
  },
  async listLocations() {
    var locations = await API.graphql({
      query: listLocations,
      variables: {
        limit: 1,
      },
    })

    return Promise.resolve(locations)
  },
  async listVisitedLocations() {
    var locations = await API.graphql({
      query: listVisitedLocations,
      variables: {
        limit: 100,
      },
    })

    return Promise.resolve(locations)
  },
  async getRandomLocations() {
    var locations = await API.graphql({
      query: getRandomLocation,
      variables: {
        count: 100,
      },
    })
    store.commit('setLocations', locations.data.getRandomLocation)

    return Promise.resolve(locations)
  },
  async getRandomLocalLocations() {
    var locations = this.getLocalLocations()
    var max = locations.length - 1
    var min = 0
    let randomItemNumber = Math.floor(Math.random() * (max - min + 1)) + min
    var location = locations[randomItemNumber]
    return Promise.resolve({ latitude: location.lat, longitude: location.lng })
  },
  async useLocation(location, geocoder, guessLocation, distanceString) {
    var input = {}
    await this.getLocationInformation(geocoder, {
      lat: location.latitude,
      lng: location.longitude,
    }).then((info) => {
      input.locationVisitedId = location.id
      input.userVisitedLocationsId = store.getters.currentUsername
      input.guessLatitude = guessLocation.latitude
      input.guessLongitude = guessLocation.longitude
      input.distance = distanceString
      input.city = info.city
      input.cityAlt = info.cityAlt
      input.country = info.country
      input.countryCode = info.countryCode
    })

    const visitedLocation = await API.graphql({
      query: createVisitedLocation,
      variables: {
        input: input,
      },
    })

    return Promise.resolve(visitedLocation)
  },
  async getLocationInformation(geocoder, location) {
    var country = null
    var countryCode = null
    var city = null
    var cityAlt = null

    await geocoder
      .geocode({ location: location })
      .then((response) => {
        if (response.results[0]) {
          let results = response.results[0].address_components
          for (var r = 0; r < results.length; r += 1) {
            var result = results[r]

            if (!city && result.types[0] === 'locality') {
              city = result.long_name
            } else if (
              !cityAlt &&
              result.types[0] === 'administrative_area_level_1'
            ) {
              cityAlt = result.short_name
            } else if (!country && result.types[0] === 'country') {
              country = result.long_name
              countryCode = result.short_name
            }

            if (city && country) {
              break
            }
          }
        } else {
          console.log('no results were found')
        }
      })
      .catch((e) => window.alert('Geocoder failed due to: ' + e))

    var data = {
      city: city,
      cityAlt: cityAlt,
      country: country,
      countryCode: countryCode,
    }
    return Promise.resolve(data)
  },
  async upateVisitedLocation(id, city, cityAlt, country, countryCode) {
    var input = {
      id: id,
      city: city,
      cityAlt: cityAlt,
      country: country,
      countryCode: countryCode,
    }
    const visitedLocation = await API.graphql({
      query: updateVisitedLocation,
      variables: {
        input: input,
      },
    })

    return Promise.resolve(visitedLocation)
  },
  async deleteLocation(id) {
    await API.graphql({
      query: deleteLocation,
      variables: {
        input: { id: id },
      },
    })

    return Promise.resolve(id)
  },
  getLocalLocations() {
    return [
      { lat: 58.1700645868, lng: 8.02225065945 },
      { lat: 63.6805517636, lng: 12.8121244245 },
      { lat: 67.9672548617, lng: 19.9670591299 },
      { lat: 58.1031300132, lng: 7.95919745869 },
      { lat: 69.9363616901, lng: 26.9312413669 },
      { lat: 65.4445251152, lng: 13.4040205807 },
      { lat: 62.8229408251, lng: 6.88218393398 },
      { lat: 69.5720010868, lng: 18.650521485 },
      { lat: 63.6563666745, lng: 9.06547669894 },
      { lat: 58.5207530585, lng: 7.43350179916 },
      { lat: 58.4676186935, lng: 6.37716544816 },
      { lat: 65.7359503636, lng: 15.1466788685 },
      { lat: 58.1073927861, lng: 7.94422694027 },
      { lat: 63.280551342, lng: 14.9167440066 },
      { lat: 69.4538980421, lng: 18.3691144038 },
      { lat: 58.8864122501, lng: 5.66045788809 },
      { lat: 64.2600593194, lng: 10.6242065179 },
      { lat: 63.5700839936, lng: 14.8786056476 },
      { lat: 59.1483688869, lng: 10.2055921057 },
      { lat: 59.472404113, lng: 11.1133851981 },
      { lat: 64.671097102, lng: 11.3056004833 },
      { lat: -38.1652649749, lng: 143.127579124 },
      { lat: -33.2806619917, lng: 117.903489075 },
      { lat: -37.0970296512, lng: 141.776964761 },
      { lat: -33.10460722, lng: 151.473356315 },
      { lat: -34.5484731057, lng: 117.148012832 },
      { lat: -17.2906151816, lng: 145.515370533 },
      { lat: -24.8183671673, lng: 152.4661862 },
      { lat: -31.9998174594, lng: 115.917060884 },
      { lat: -32.534748, lng: 115.719205 },
      { lat: -31.689022, lng: 115.70157 },
      { lat: -33.742477, lng: 117.593195 },
      { lat: -34.531554, lng: 135.432578 },
      { lat: -37.852011, lng: 145.163327 },
      { lat: -35.794063, lng: 137.788057 },
      { lat: -33.615989, lng: 151.20633 },
      { lat: -26.528145, lng: 153.090663 },
      { lat: -42.509851, lng: 147.915104 },
      { lat: 50.5844204169, lng: 3.81384723519 },
      { lat: 51.0998430818, lng: 3.98659292989 },
      { lat: 50.9375447526, lng: 5.51832200054 },
      { lat: 51.1267261961, lng: 3.08359627583 },
      { lat: 51.2135567969, lng: 3.57618821694 },
      { lat: 50.4225580805, lng: 4.83910842267 },
      { lat: 50.858189635, lng: 4.40068987987 },
      { lat: 50.4104840679, lng: 4.58851338186 },
      { lat: 50.9561011021, lng: 4.29819468677 },
      { lat: 50.9662873759, lng: 4.04318828396 },
      { lat: 50.6401612615, lng: 5.79914327622 },
      { lat: 50.3809839202, lng: 5.71219135121 },
      { lat: 50.0486684614, lng: 5.49948394394 },
      { lat: 50.8104885523, lng: 5.15086621159 },
      { lat: 50.8583498429, lng: 4.40890565252 },
      { lat: 50.4185551105, lng: 6.07377876772 },
      { lat: 50.6373700582, lng: 4.62286405183 },
      { lat: 50.4809524911, lng: 4.60670563687 },
      { lat: 50.4638855597, lng: 3.92929424218 },
      { lat: 51.0855266783, lng: 4.00681828151 },
      { lat: 50.593621461, lng: 4.00808947544 },
      { lat: 50.4349976789, lng: 4.60852859534 },
      { lat: 51.0090962643, lng: 3.33080548393 },
      { lat: 51.1306530459, lng: 5.73285634694 },
      { lat: 50.7349079538, lng: 3.38528375812 },
      { lat: 50.4681090305, lng: 4.63915089424 },
      { lat: 51.0807976333, lng: 5.79432401688 },
      { lat: 50.988592333, lng: 3.51204654335 },
      { lat: 50.9071120395, lng: 3.94374108711 },
      { lat: 50.1761301715, lng: 5.28637492635 },
      { lat: 47.1013494331, lng: 7.57460489901 },
      { lat: 47.2812451837, lng: 8.82475230711 },
      { lat: 47.3887514799, lng: 8.67544479851 },
      { lat: 46.9377496019, lng: 6.67055423068 },
      { lat: 46.9782877988, lng: 6.89168038589 },
      { lat: 47.5642958742, lng: 7.60906799351 },
      { lat: 47.3521966338, lng: 8.53076690055 },
      { lat: 46.5160972493, lng: 6.70143547883 },
      { lat: 47.6735578869, lng: 8.62304905996 },
      { lat: 46.7472632588, lng: 9.8141652622 },
      { lat: 46.5644894334, lng: 6.57657931267 },
      { lat: 46.081828794, lng: 7.2138899354 },
      { lat: 47.1354593754, lng: 7.24802289723 },
      { lat: 46.5176568069, lng: 6.33973562411 },
      { lat: 46.7669344145, lng: 9.62685830764 },
      { lat: 46.8073454009, lng: 9.92215517558 },
      { lat: 46.7082833391, lng: 6.75245178403 },
      { lat: 46.4854020284, lng: 6.45788853317 },
      { lat: 46.598485326, lng: 6.48381048908 },
      { lat: 47.3887826731, lng: 8.54624627796 },
      { lat: 46.6639191409, lng: 7.82759904114 },
      { lat: 46.2027500543, lng: 6.13014383888 },
      { lat: 46.5476701627, lng: 6.53762798027 },
      { lat: 46.9788672709, lng: 6.86241692151 },
      { lat: 46.8659448034, lng: 6.96619988061 },
      { lat: 46.6531759444, lng: 7.4332592516 },
      { lat: 46.9228168337, lng: 7.37492218504 },
      { lat: 46.8115683109, lng: 7.59385002342 },
      { lat: 47.3471085808, lng: 8.57423905996 },
      { lat: 47.5778203053, lng: 7.56906081981 },
      { lat: 50.4119484428, lng: 15.5243020251 },
      { lat: 49.8678520529, lng: 16.3644522417 },
      { lat: 50.3459753993, lng: 14.5812648535 },
      { lat: 49.9371441271, lng: 13.2767023923 },
      { lat: 50.1485628198, lng: 14.578834647 },
      { lat: 49.2129687138, lng: 16.4702553333 },
      { lat: 50.4098941094, lng: 16.1590707664 },
      { lat: 50.4263095916, lng: 12.8740819744 },
      { lat: 50.1050303223, lng: 14.372668712 },
      { lat: 49.7475617589, lng: 14.1045452437 },
      { lat: 49.5745036814, lng: 14.122168842 },
      { lat: 49.0077927897, lng: 14.9229038588 },
      { lat: 49.054140762, lng: 15.4846072975 },
      { lat: 49.9522441258, lng: 15.7127309002 },
      { lat: 50.3319577675, lng: 15.8779003047 },
      { lat: 49.2014552964, lng: 13.6068199856 },
      { lat: 49.6881785172, lng: 18.3690696719 },
      { lat: 49.8881761627, lng: 18.2484376106 },
      { lat: 49.5282556041, lng: 14.35390844 },
      { lat: 50.5427408809, lng: 15.7544920895 },
      { lat: 50.4055219893, lng: 13.6876109662 },
      { lat: 49.1487562096, lng: 15.8263642793 },
      { lat: 48.9707721218, lng: 14.0485599898 },
      { lat: 50.5766429844, lng: 15.8881352045 },
      { lat: 50.2158078186, lng: 17.0508051913 },
      { lat: 49.7796017491, lng: 15.9566916142 },
      { lat: 50.1379107288, lng: 15.1107445708 },
      { lat: 49.2216336279, lng: 13.7990070773 },
      { lat: 49.4107250795, lng: 13.8665593519 },
      { lat: 49.6810297346, lng: 16.8182532873 },
      { lat: 53.0683132431, lng: 7.06120517517 },
      { lat: 51.3365008885, lng: 12.5257000914 },
      { lat: 50.902196258, lng: 6.98064471773 },
      { lat: 52.573852028, lng: 13.4013698631 },
      { lat: 52.484628554, lng: 13.5461091192 },
      { lat: 52.5749119776, lng: 13.3444097101 },
      { lat: 52.5215942282, lng: 13.2358896298 },
      { lat: 51.1669772877, lng: 7.13546419817 },
      { lat: 51.3567977378, lng: 6.73971962521 },
      { lat: 50.7136737559, lng: 7.11358924142 },
      { lat: 51.0597811843, lng: 13.8327748673 },
      { lat: 52.618968762, lng: 13.3950412829 },
      { lat: 51.3373911798, lng: 12.3694449812 },
      { lat: 54.8775532351, lng: 9.91461195218 },
      { lat: 55.4617742378, lng: 9.61278633355 },
      { lat: 55.4634223289, lng: 8.6510829625 },
      { lat: 55.4050887898, lng: 11.9334651829 },
      { lat: 56.0512792787, lng: 9.68441360067 },
      { lat: 55.6368411446, lng: 9.69107230773 },
      { lat: 55.6168336489, lng: 8.23915053397 },
      { lat: 56.1340270493, lng: 9.03598182978 },
      { lat: 55.702838278, lng: 12.4689962059 },
      { lat: 55.7428229143, lng: 12.5504190461 },
      { lat: 57.4810529857, lng: 10.0374280178 },
      { lat: 55.5206682537, lng: 8.45884337332 },
      { lat: 57.7160925779, lng: 10.5687092422 },
      { lat: 56.0300033324, lng: 12.5745419728 },
      { lat: 56.4516576915, lng: 8.75336979449 },
      { lat: 56.5601634225, lng: 9.84592379382 },
      { lat: 55.5040264791, lng: 8.45648079713 },
      { lat: 56.4691216221, lng: 8.95980735898 },
      { lat: 56.3256922446, lng: 9.41971759269 },
      { lat: 56.0173484443, lng: 9.53959117326 },
      { lat: 56.2872161212, lng: 10.580887923 },
      { lat: 55.4764910388, lng: 9.16184791352 },
      { lat: 56.3897065373, lng: 9.33893879833 },
      { lat: 55.9600227204, lng: 10.2502291696 },
      { lat: 56.153371525, lng: 10.0456623563 },
      { lat: 55.7380523453, lng: 12.3583967498 },
      { lat: 55.7149599337, lng: 9.56338530838 },
      { lat: 57.1749466051, lng: 10.1090837929 },
      { lat: 55.6872639942, lng: 12.2497101062 },
      { lat: 55.4324527055, lng: 9.22878869694 },
      { lat: 58.980534692, lng: 22.6175014951 },
      { lat: 59.4084145123, lng: 26.1866455642 },
      { lat: 58.0013586988, lng: 27.5355851348 },
      { lat: 58.701316946, lng: 22.4905006883 },
      { lat: 59.2801961204, lng: 26.2275383556 },
      { lat: 57.9769775097, lng: 25.9382704311 },
      { lat: 58.0666469313, lng: 27.0812209725 },
      { lat: 58.748657817, lng: 26.4157204657 },
      { lat: 57.8140123946, lng: 26.207452918 },
      { lat: 58.1134073797, lng: 27.1215964811 },
      { lat: 57.8367457102, lng: 27.1527836986 },
      { lat: 58.3248714055, lng: 24.6868568295 },
      { lat: 58.3444587092, lng: 26.700418554 },
      { lat: 58.6967102264, lng: 24.93483661 },
      { lat: 58.1036223494, lng: 27.4992503388 },
      { lat: 58.2402445654, lng: 22.2029926508 },
      { lat: 59.2743130009, lng: 27.5026779309 },
      { lat: 58.6017389455, lng: 26.0691329829 },
      { lat: 58.5064024038, lng: 22.8699717167 },
      { lat: 57.982396805, lng: 26.5502096197 },
      { lat: 59.0968992525, lng: 27.3079071995 },
      { lat: 59.3792783211, lng: 25.8609688 },
      { lat: 59.4087464442, lng: 27.3154683644 },
      { lat: 58.290691434, lng: 27.264002734 },
      { lat: 58.6063918643, lng: 26.5066457469 },
      { lat: 59.0133193097, lng: 27.5507926432 },
      { lat: 58.3375078635, lng: 27.1219653453 },
      { lat: 58.2141521934, lng: 27.2724922677 },
      { lat: 57.7276773709, lng: 26.1789699014 },
      { lat: 59.0883591719, lng: 26.3464315066 },
      { lat: 41.3678317523, lng: -7.13209842145 },
      { lat: 39.6066563996, lng: -8.66796789275 },
      { lat: 40.9803200337, lng: -7.608444315 },
      { lat: 41.7664544258, lng: 2.25267955973 },
      { lat: 41.3231370757, lng: -8.20510817854 },
      { lat: 40.7760296528, lng: -7.06848204554 },
      { lat: 37.0801085797, lng: -4.45808745439 },
      { lat: 41.7055075162, lng: -7.82172072994 },
      { lat: 41.3393057742, lng: 1.52344679861 },
      { lat: 40.4539406908, lng: -8.50731300686 },
      { lat: 39.47478858, lng: 3.05305628377 },
      { lat: 39.8404866233, lng: -8.13019821354 },
      { lat: 39.4770206124, lng: -8.75638240279 },
      { lat: 41.9171333699, lng: -0.302709747816 },
      { lat: 41.3856040547, lng: -8.02328636737 },
      { lat: 42.6543415677, lng: -1.38181634968 },
      { lat: 38.1473915573, lng: -8.46085986412 },
      { lat: 40.492792855, lng: -8.48253880949 },
      { lat: 40.7680024902, lng: -8.32164566924 },
      { lat: 39.8833338978, lng: -7.56599514343 },
      { lat: 43.0908327342, lng: -8.81076578569 },
      { lat: 36.7142542774, lng: -4.44979198526 },
      { lat: 42.0406511688, lng: -1.59070763539 },
      { lat: 43.4040667467, lng: -5.62091938921 },
      { lat: 42.0594422906, lng: -8.59698454765 },
      { lat: 41.0598995883, lng: -8.58048009886 },
      { lat: 41.4917797998, lng: 1.19129816074 },
      { lat: 41.1773532344, lng: 0.568972634447 },
      { lat: 38.8231369028, lng: -8.768034065 },
      { lat: 27.807289995, lng: -17.9079257597 },
      { lat: 64.8051622344, lng: 26.0013155843 },
      { lat: 62.3376999065, lng: 27.0217791362 },
      { lat: 61.913910539, lng: 25.1735871451 },
      { lat: 60.8506595387, lng: 25.1737427648 },
      { lat: 63.1379935199, lng: 28.0336630826 },
      { lat: 60.6783549661, lng: 24.7633129697 },
      { lat: 60.3817745979, lng: 24.977446455 },
      { lat: 63.935212403, lng: 25.6767304485 },
      { lat: 60.9995163718, lng: 28.1622956506 },
      { lat: 62.6091422614, lng: 28.5291569666 },
      { lat: 61.8292439848, lng: 27.5111448124 },
      { lat: 60.4857373252, lng: 26.3018323972 },
      { lat: 62.127806532, lng: 27.6536635041 },
      { lat: 62.9320840865, lng: 27.974255821 },
      { lat: 64.4605277712, lng: 25.733972668 },
      { lat: 62.8434062301, lng: 28.4349979709 },
      { lat: 64.5732301566, lng: 26.8516411347 },
      { lat: 60.2517338271, lng: 24.9837318838 },
      { lat: 62.9084786016, lng: 27.6317847017 },
      { lat: 61.576284079, lng: 24.8883912444 },
      { lat: 65.3763425633, lng: 25.3194940783 },
      { lat: 61.5491624549, lng: 24.6412307009 },
      { lat: 60.5796698792, lng: 26.0735490099 },
      { lat: 62.2020970896, lng: 25.3814152896 },
      { lat: 62.6222586567, lng: 31.0000693219 },
      { lat: 66.0702664642, lng: 27.0381511046 },
      { lat: 65.2350128837, lng: 29.0235002268 },
      { lat: 61.0503151952, lng: 24.9006640417 },
      { lat: 62.2590505976, lng: 25.5179205329 },
      { lat: 63.8255264134, lng: 22.8968937682 },
      { lat: 43.2712130616, lng: 2.8429548835 },
      { lat: 48.8321413284, lng: 8.02392849805 },
      { lat: 43.7898306417, lng: 0.0230082001115 },
      { lat: 43.3775590018, lng: 3.06510138516 },
      { lat: 43.8830821399, lng: -0.822441803918 },
      { lat: 50.3176592846, lng: 3.34931606777 },
      { lat: 43.6818410022, lng: -1.09038004256 },
      { lat: 45.1225133163, lng: 0.007613934478 },
      { lat: 43.4217399781, lng: 3.72349253762 },
      { lat: 44.446316561, lng: 7.49455776905 },
      { lat: 50.4720886168, lng: 3.22429355972 },
      { lat: 42.8644088715, lng: 1.09045056751 },
      { lat: 44.1240594388, lng: 3.22943434848 },
      { lat: 47.1185485657, lng: 6.51083056972 },
      { lat: 43.5230726506, lng: 1.1677920211 },
      { lat: 49.268265536, lng: 4.73205821549 },
      { lat: 43.3785726974, lng: 5.68085346916 },
      { lat: 45.0510046662, lng: -0.537325487038 },
      { lat: 45.155747247, lng: -0.42367128818 },
      { lat: 42.1007829716, lng: 8.84364078275 },
      { lat: 48.9642134302, lng: 5.72154908076 },
      { lat: 43.2983198728, lng: 2.82068898671 },
      { lat: 47.9328514584, lng: 7.22986073437 },
      { lat: 48.7568276955, lng: 6.89322755466 },
      { lat: 47.3944536323, lng: 6.80147481439 },
      { lat: 44.265497981, lng: 2.44603609909 },
      { lat: 44.3640843383, lng: 1.06734221386 },
      { lat: 44.6469422631, lng: 2.41188146496 },
      { lat: 44.0332755658, lng: 8.09660540905 },
      { lat: 43.2137928157, lng: 1.21189489202 },
      { lat: 52.8948538926, lng: -7.51315925825 },
      { lat: 53.4342155426, lng: -6.63164619237 },
      { lat: 51.9660774671, lng: -8.76155186801 },
      { lat: 53.1399802804, lng: -8.75605306964 },
      { lat: 52.5730841319, lng: -7.19396964747 },
      { lat: 53.9726993618, lng: -7.66209064056 },
      { lat: 52.6326922979, lng: -7.78913153776 },
      { lat: 53.2943959563, lng: -7.08826317295 },
      { lat: 52.4458633579, lng: -9.05677305727 },
      { lat: 54.1214106662, lng: -6.85937792115 },
      { lat: 52.272634724, lng: -8.09898421795 },
      { lat: 53.004660759, lng: -7.5644023226 },
      { lat: 52.002280421, lng: -8.34004566582 },
      { lat: 51.8730730313, lng: -9.44413523748 },
      { lat: 53.7663744532, lng: -6.93662016219 },
      { lat: 55.1790691603, lng: -7.45499903426 },
      { lat: 53.5973229207, lng: -8.79603650281 },
      { lat: 55.0058860339, lng: -8.53011678484 },
      { lat: 53.6662374123, lng: -6.54961283894 },
      { lat: 53.4539261751, lng: -6.18488549074 },
      { lat: 53.7235238746, lng: -6.65182120445 },
      { lat: 52.2556586219, lng: -9.43865384715 },
      { lat: 51.9052040019, lng: -8.38422621894 },
      { lat: 54.1526653385, lng: -7.13346209052 },
      { lat: 55.1459452174, lng: -7.6584295202 },
      { lat: 52.5191753621, lng: -7.99600959931 },
      { lat: 53.5346754835, lng: -8.42007178583 },
      { lat: 53.1800468882, lng: -8.80296360335 },
      { lat: 52.6390512669, lng: -6.23637438366 },
      { lat: 52.1732291304, lng: -8.16484957809 },
      { lat: 42.9527872149, lng: 13.3200129954 },
      { lat: 45.1687296434, lng: 8.09030002518 },
      { lat: 45.8004102761, lng: 11.9154714166 },
      { lat: 44.9267477369, lng: 11.2009387356 },
      { lat: 43.8982210984, lng: 8.02435403497 },
      { lat: 44.3630446225, lng: 8.17579282978 },
      { lat: 44.8592937194, lng: 8.48529484245 },
      { lat: 40.2959434902, lng: 16.1369894549 },
      { lat: 46.0045415808, lng: 10.9853291223 },
      { lat: 45.4508464861, lng: 11.9001849576 },
      { lat: 40.5806370101, lng: 15.7383348984 },
      { lat: 42.8647356813, lng: 13.774889565 },
      { lat: 44.0377891945, lng: 12.3799309465 },
      { lat: 37.6524617504, lng: 14.9078636114 },
      { lat: 41.3698125454, lng: 15.1209037567 },
      { lat: 45.9410043706, lng: 12.3018595155 },
      { lat: 45.0489998333, lng: 7.48813834264 },
      { lat: 45.4195098368, lng: 9.26848524733 },
      { lat: 43.9225087984, lng: 12.1646487807 },
      { lat: 45.1441154196, lng: 10.7820090572 },
      { lat: 41.8807069858, lng: 14.8158196102 },
      { lat: 44.7752308837, lng: 8.3567222644 },
      { lat: 46.0212526609, lng: 12.4092044593 },
      { lat: 45.5836888151, lng: 11.275440308 },
      { lat: 45.7027405161, lng: 11.3602890297 },
      { lat: 42.9146390208, lng: 13.8792038499 },
      { lat: 41.8161273511, lng: 12.7363886662 },
      { lat: 41.4633461406, lng: 13.7743613267 },
      { lat: 40.6160253159, lng: 17.2624890821 },
      { lat: 45.7151922638, lng: 9.20556077131 },
      { lat: 37.5622017893, lng: 126.965842523 },
      { lat: 35.1744291693, lng: 129.064159983 },
      { lat: 37.538145657, lng: 127.067402439 },
      { lat: 37.5241041486, lng: 126.922536014 },
      { lat: 37.556721796, lng: 127.002071696 },
      { lat: 37.5238746304, lng: 126.857832177 },
      { lat: 37.5489475994, lng: 127.110723206 },
      { lat: 35.1614615423, lng: 128.982049352 },
      { lat: 37.4915091179, lng: 126.881955506 },
      { lat: 37.5661929991, lng: 127.035690202 },
      { lat: 37.5058315262, lng: 127.061961035 },
      { lat: 37.5469755084, lng: 126.945424274 },
      { lat: 35.3393325575, lng: 129.256800965 },
      { lat: 35.1553308618, lng: 128.999320155 },
      { lat: 37.4855280984, lng: 127.027229636 },
      { lat: 55.9683305171, lng: 23.8236970145 },
      { lat: 54.6978029707, lng: 25.4085568728 },
      { lat: 54.5553088149, lng: 23.3538436377 },
      { lat: 56.0075441882, lng: 23.6756487978 },
      { lat: 55.6667141897, lng: 25.9149580657 },
      { lat: 56.1610371264, lng: 23.8160387314 },
      { lat: 55.8470738057, lng: 22.3380588037 },
      { lat: 55.8287954062, lng: 21.3383597975 },
      { lat: 56.2237467626, lng: 23.8210850764 },
      { lat: 55.287755451, lng: 24.1682477753 },
      { lat: 54.1741316847, lng: 24.994396308 },
      { lat: 54.9447739228, lng: 24.7819656818 },
      { lat: 54.8889361233, lng: 24.8566683911 },
      { lat: 54.4433717979, lng: 25.0817021272 },
      { lat: 56.2303808272, lng: 24.583383404 },
      { lat: 55.363166125, lng: 25.5055069433 },
      { lat: 55.3648657788, lng: 22.8735135714 },
      { lat: 54.2355959757, lng: 24.1212307692 },
      { lat: 54.4608647785, lng: 25.4234179348 },
      { lat: 55.5778095911, lng: 21.7778716827 },
      { lat: 54.651666885, lng: 23.0439751916 },
      { lat: 55.3811782051, lng: 23.7168921976 },
      { lat: 54.138594934, lng: 23.7041278093 },
      { lat: 55.372462105, lng: 22.4958559346 },
      { lat: 54.9165055196, lng: 24.9260648902 },
      { lat: 54.1413259822, lng: 24.7988728798 },
      { lat: 54.9416697335, lng: 23.8018783232 },
      { lat: 55.4387987897, lng: 23.1406935432 },
      { lat: 56.0035320581, lng: 22.1441250793 },
      { lat: 56.030825793, lng: 21.2611975897 },
      { lat: 56.4367179646, lng: 21.6638306234 },
      { lat: 56.9211584001, lng: 23.8636494877 },
      { lat: 57.2621556217, lng: 25.4313381836 },
      { lat: 57.3328926965, lng: 25.8159200572 },
      { lat: 56.4473469051, lng: 21.6134409745 },
      { lat: 57.1540140716, lng: 27.4990055902 },
      { lat: 56.172116709, lng: 25.7620503584 },
      { lat: 57.6779311981, lng: 24.5292411376 },
      { lat: 56.9135461077, lng: 24.2412612554 },
      { lat: 56.9394836952, lng: 23.9185569532 },
      { lat: 56.402305969, lng: 23.9925499475 },
      { lat: 57.1391950588, lng: 26.7095437348 },
      { lat: 56.9819053556, lng: 23.9368397345 },
      { lat: 56.1573654126, lng: 27.6475874683 },
      { lat: 56.3976226625, lng: 25.7270446223 },
      { lat: 56.6432705747, lng: 26.7302873365 },
      { lat: 56.9487079972, lng: 24.0283486978 },
      { lat: 57.0924101584, lng: 24.5321139227 },
      { lat: 57.5827006322, lng: 24.5131394558 },
      { lat: 56.791459172, lng: 23.9497533113 },
      { lat: 57.4295589216, lng: 21.6489559314 },
      { lat: 57.2677212784, lng: 21.6786036102 },
      { lat: 56.9761408954, lng: 24.2341858532 },
      { lat: 56.4484945223, lng: 27.0545797061 },
      { lat: 56.6583026604, lng: 22.4935808788 },
      { lat: 57.0780227308, lng: 26.9640023848 },
      { lat: 56.9641043384, lng: 24.0104646071 },
      { lat: 57.3725936061, lng: 21.5664733367 },
      { lat: 56.5349404628, lng: 24.5148389196 },
      { lat: 57.2463930074, lng: 27.2040811068 },
      { lat: 26.3423159734, lng: -99.2924077605 },
      { lat: 25.7410277583, lng: -100.143587319 },
      { lat: 19.1083233611, lng: -98.3517204756 },
      { lat: 25.5877377835, lng: -103.448552381 },
      { lat: 26.4890612829, lng: -105.484614375 },
      { lat: 26.5030049257, lng: -100.161339605 },
      { lat: 53.2506142652, lng: 6.76381131529 },
      { lat: 52.7611493336, lng: 5.09933237854 },
      { lat: 52.6563112096, lng: 6.90592036612 },
      { lat: 53.0723697576, lng: 5.72752512447 },
      { lat: 51.6596109632, lng: 5.35319022861 },
      { lat: 52.6465329898, lng: 4.74408541299 },
      { lat: 52.5196927318, lng: 4.95924541002 },
      { lat: 52.4667177712, lng: 6.07239828608 },
      { lat: 52.3251409373, lng: 4.61158405583 },
      { lat: 52.3720236652, lng: 6.69581284597 },
      { lat: 51.5383116554, lng: 3.63373484103 },
      { lat: 52.8207356991, lng: 6.0235210555 },
      { lat: 52.4786693245, lng: 4.77114204091 },
      { lat: 52.0937064352, lng: 4.53504974465 },
      { lat: 51.984531213, lng: 4.25357185846 },
      { lat: 51.9390164279, lng: 6.00387660539 },
      { lat: 51.5449522049, lng: 3.4686149202 },
      { lat: 51.4559092595, lng: 5.58681632759 },
      { lat: 52.0152991843, lng: 5.92851574933 },
      { lat: 53.2365898754, lng: 6.3047767313 },
      { lat: 50.9767471117, lng: 5.92924024559 },
      { lat: 52.8564082215, lng: 6.49475483038 },
      { lat: 52.3528345637, lng: 5.61552925036 },
      { lat: 53.0391578104, lng: 6.82687549846 },
      { lat: 53.0715081717, lng: 6.60329436969 },
      { lat: 52.9365881839, lng: 4.73760781159 },
      { lat: 51.4751101471, lng: 5.71175588894 },
      { lat: 51.6846678496, lng: 4.86709034101 },
      { lat: 53.3742381066, lng: 6.6037029744 },
      { lat: 52.4351228645, lng: 6.10591707672 },
      { lat: -34.7507122487, lng: 149.46945139 },
      { lat: 51.8850501182, lng: 20.8561920175 },
      { lat: 50.259791599, lng: 19.7849056554 },
      { lat: 50.5572523622, lng: 20.0694759183 },
      { lat: 50.7351138156, lng: 23.5212403676 },
      { lat: 50.0877502306, lng: 20.5869787915 },
      { lat: 53.3411773412, lng: 19.293197742 },
      { lat: 50.549007314, lng: 19.9701652647 },
      { lat: 54.3527842706, lng: 18.2175657771 },
      { lat: 53.2737787679, lng: 18.4552981499 },
      { lat: 51.123094641, lng: 15.8896424474 },
      { lat: 53.3301151779, lng: 14.4287581905 },
      { lat: 53.8527516612, lng: 22.9538100283 },
      { lat: 54.0899301446, lng: 18.9483921373 },
      { lat: 54.0997303427, lng: 19.2748107941 },
      { lat: 51.6448814264, lng: 21.1613398179 },
      { lat: 50.4321060107, lng: 21.7504296937 },
      { lat: 49.8503721443, lng: 19.0683550675 },
      { lat: 50.132297674, lng: 19.9799924247 },
      { lat: 53.6810058798, lng: 22.3921923208 },
      { lat: 52.470113375, lng: 21.3240651861 },
      { lat: 50.1807296457, lng: 18.9112702789 },
      { lat: 50.9927622708, lng: 18.5617708786 },
      { lat: 53.0211904206, lng: 18.6033935954 },
      { lat: 51.1979151581, lng: 16.9052589004 },
      { lat: 53.8355514587, lng: 14.6080939668 },
      { lat: 52.2306009345, lng: 22.3901987288 },
      { lat: 52.9611472859, lng: 16.840969556 },
      { lat: 52.4137807912, lng: 17.8943338424 },
      { lat: 51.2538871337, lng: 16.5234511687 },
      { lat: 50.4791401732, lng: 20.2498106571 },
      { lat: 44.9929019664, lng: 25.6424181366 },
      { lat: 47.0581595941, lng: 21.9353039198 },
      { lat: 46.8438471254, lng: 24.2995256919 },
      { lat: 47.2622609001, lng: 27.1921115289 },
      { lat: 46.0666387686, lng: 24.4799632857 },
      { lat: 47.580577317, lng: 22.9667153937 },
      { lat: 44.2125213079, lng: 23.7776204657 },
      { lat: 44.9678265463, lng: 26.3457905133 },
      { lat: 45.6290850566, lng: 21.7532993374 },
      { lat: 44.6969871577, lng: 23.4001465722 },
      { lat: 46.7744560821, lng: 27.6503598561 },
      { lat: 43.8274847203, lng: 25.4206764876 },
      { lat: 45.7297559192, lng: 21.2077775008 },
      { lat: 47.7888242362, lng: 22.7850486264 },
      { lat: 47.5221355739, lng: 22.0748817105 },
      { lat: 47.3838233745, lng: 25.1691555442 },
      { lat: 44.6625635778, lng: 25.5711876241 },
      { lat: 46.31945004, lng: 27.0737537951 },
      { lat: 44.3043239842, lng: 24.0404873866 },
      { lat: 47.9568090072, lng: 26.3954760111 },
      { lat: 45.4543490746, lng: 25.2728181371 },
      { lat: 44.7981699758, lng: 28.6902455519 },
      { lat: 47.44031774, lng: 25.5516537493 },
      { lat: 46.244003628, lng: 27.3967706934 },
      { lat: 46.4438089403, lng: 22.4785348853 },
      { lat: 47.4459002432, lng: 23.7965432092 },
      { lat: 47.6331051712, lng: 26.2028160619 },
      { lat: 44.0098345588, lng: 25.6380778602 },
      { lat: 47.1806453745, lng: 26.749010341 },
      { lat: 46.5395011288, lng: 24.5485338553 },
    ]
  },
}
