<script setup>
import '@aws-amplify/ui-vue/styles.css'
import { Amplify } from 'aws-amplify'

// const auth = useAuthenticator()
</script>

<template>
  <router-view />
  <div class="coffee-button">
    <a
      href="https://www.buymeacoffee.com/blained"
      target="_blank"
      title="Buy me a coffee"
      ><img class="coffee-image" src="./assets/bmc-logo-no-background.png"
    /></a>
  </div>
  <vue-cookie-comply style="z-index: 99999" :preferences="preferences" />
</template>

<script>
import awsmobile from './aws-exports'

Amplify.configure(awsmobile)

export default {
  data() {
    return {
      user: {},
      showLogin: false,
      preferences: [
        {
          title: 'Google Ads',
          description:
            'Google ads are shown through the game.  This is our main source of income in order to keep the servers running. You cannot turn this off.',
          items: [{ label: 'Active', value: 'ads', isRequired: true }],
        },
      ],
    }
  },
}
</script>

<style>
.cookie-comply__modal {
  z-index: 99999 !important;
}
.coffee-button {
  position: absolute;
  bottom: 50px;
  left: 0px;
  margin: 10px;
  z-index: 99998;
  width: 30px;
  height: 30px;
}
.coffee-image {
  width: 30px;
  height: 30px;
}
#app {
  margin: 0;
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  background-color: black;
  margin: 0;
  width: 100vw;
}
html {
  height: -webkit-fill-available;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

h4 {
  font-size: 20px;
}
.welcome-container {
  margin-left: 20px;
  font-size: 1.1em;
  color: #c002eb;
}
.signout-link {
  margin-left: 5px;
  color: #c002eb;
  cursor: pointer;
}
.login-container {
  margin: 10px;
  position: fixed;
  z-index: 1000;
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(
    --amplify-colors-brand-secondary-80
  );
  --amplify-components-button-primary-hover-background-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-primary-focus-background-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-primary-active-background-color: var(
    --amplify-colors-brand-secondary-100
  );
  --amplify-components-button-link-color: var(
    --amplify-colors-brand-secondary-80
  );
  --amplify-components-button-link-hover-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-link-focus-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-link-active-color: var(
    --amplify-colors-brand-secondary-100
  );
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-hover-background-color: transparent;
}
</style>
